.VideoViewContainer{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.VideoViewContainer>.VideoPlayer{
    width: 60%;
    display: flex;
    
    align-content: center;
    
}
.VideoViewContainer>.VideoPlayer>.SubShow{
   background: black;
   position: absolute;  
  bottom: 20px;
  left: 0;
  right: 0;
width: 900px;
   
   color: white;
   
}