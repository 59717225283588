body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.ant-layout-sider-children{
  background: white;
}
.ant-layout-content{
  background: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.VideoPlayer{
    width: 50%;
    margin: 10px;
    position: relative;
}
.EditorContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
   
}
.EditorContainer>.VideoPlayer>.SubShow{
  position: absolute;  
  bottom: 20px;
  left: 0;
  right: 0;
  color: white;
}

.SubListContainer{
    height: 50vh;
    overflow-y: scroll;
    margin:20px;
}

.ant-layout>.ant-layout-header{

    border-bottom: 2px solid var(--primary-color50); 
    font-family: "Helvetica";
    font-size: 35px;
    color: white;
    display: flex;
    flex-direction: row;
   
    justify-content: center;
    align-items: center;
   background: #FF8649;
}

.ant-layout>.ant-layout-header>a{
    font-family: "Arial";
    font-size: 35px;
    color: white;

    position: absolute;
  
    left: 50px;
}


/* Search button */
.ant-btn.ant-input-search-button.ant-btn-primary{
       
        background: white;
    
        color: #FF8649;
      
}

/* Signin button*/
.ant-layout>.ant-layout-header>.ant-btn{
    position: absolute;
    top:15px;
    right: 50px;
    
}



/* Avatar menu*/
.ant-layout>.ant-layout-header>.HeaderAvatar{
    position: absolute;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    right: 60px;
    
}
.ant-layout>.ant-layout-header>.HeaderAvatar>span{
  margin: 5px;
}
.MainPage{
    margin: 10px;
    background: white;
    margin-top: 100px;
    margin-left: 100px;
}
.ant-card-cover>img{
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    object-fit: cover;

}

.CategoryTitle{

   text-align: left;
    font-size: 30px;
}
.ant-card-cover{
    height: 150px;
}
.VideoViewContainer{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.VideoViewContainer>.VideoPlayer{
    width: 60%;
    display: flex;
    
    align-content: center;
    
}
.VideoViewContainer>.VideoPlayer>.SubShow{
   background: black;
   position: absolute;  
  bottom: 20px;
  left: 0;
  right: 0;
width: 900px;
   
   color: white;
   
}
.SideMenuContainer{
    margin-top:70px;
}
