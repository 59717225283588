.VideoPlayer{
    width: 50%;
    margin: 10px;
    position: relative;
}
.EditorContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
   
}
.EditorContainer>.VideoPlayer>.SubShow{
  position: absolute;  
  bottom: 20px;
  left: 0;
  right: 0;
  color: white;
}

.SubListContainer{
    height: 50vh;
    overflow-y: scroll;
    margin:20px;
}