
.ant-layout>.ant-layout-header{

    border-bottom: 2px solid var(--primary-color50); 
    font-family: "Helvetica";
    font-size: 35px;
    color: white;
    display: flex;
    flex-direction: row;
   
    justify-content: center;
    align-items: center;
   background: #FF8649;
}

.ant-layout>.ant-layout-header>a{
    font-family: "Arial";
    font-size: 35px;
    color: white;

    position: absolute;
  
    left: 50px;
}


/* Search button */
.ant-btn.ant-input-search-button.ant-btn-primary{
       
        background: white;
    
        color: #FF8649;
      
}

/* Signin button*/
.ant-layout>.ant-layout-header>.ant-btn{
    position: absolute;
    top:15px;
    right: 50px;
    
}



/* Avatar menu*/
.ant-layout>.ant-layout-header>.HeaderAvatar{
    position: absolute;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    right: 60px;
    
}
.ant-layout>.ant-layout-header>.HeaderAvatar>span{
  margin: 5px;
}