.MainPage{
    margin: 10px;
    background: white;
    margin-top: 100px;
    margin-left: 100px;
}
.ant-card-cover>img{
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    object-fit: cover;

}

.CategoryTitle{

   text-align: left;
    font-size: 30px;
}
.ant-card-cover{
    height: 150px;
}